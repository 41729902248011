import { useNavigate } from "react-router-dom";

// components
import Banner from "../components/Banner";
import Presenter from "../components/Presenter";
import Scheduler from "../components/Scheduler";
import Sponsors from "../components/Sponsors";
import News from "../components/News";

// assets
import banner from "../assets/banners/banner3.jpg";
import competitionleft from "../assets/competitions/valentines day_first.jpeg";
import competitionright from "../assets/competitions/valentines day second.jpeg";
// import christmas from '../assets/banners/12 days of christmas.JPG'
import banner2 from "../assets/banners/banner6.jpg";
import banner9 from "../assets/banners/banner9.png";
import banner10 from "../assets/banners/banner10.PNG";
import mobileBanner2 from "../assets/banners/mobile-banner.jpg";
import MeNews from "../components/MeNews";
import { Link } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className="home">
      <Banner />
      <div className="py-8 space-y-8">
        <div className="text-center mx-auto space-y-8">
          <img src={banner10} alt="Banner 10" width={1000} className="mx-auto" />
          {/* <div className="">
            <a
              href="https://www.ao-arena.com/about/30-birthday-competition"
              target="_blank"
              rel="noreferrer"
              className="btn"
            >
              Share Your Memories
            </a>
          </div> */}
        </div>
        <div className="">
          <h2>Happy Radio Schedule</h2>
          <Scheduler />
          <div className="text-center">
            <button onClick={() => navigate("/schedule")} className="btn mt-8">
              View Full Schedule
            </button>
          </div>
        </div>
        <div className="">
          {/* <Link to='/competition'>
            <h2>6 Weeks of Summer online Promotion on Happy Radio 2024 - Click Here for Specific Rules</h2>
          </Link> */}
          {/*<div className="pb-16 px-6 md:px-16 text-center text-xl leading-relaxed flex flex-wrap lg:flex-nowrap gap-10">
          <img src={competitionleft} className="" alt="Happy Radio UK Competition" height={500} width={500}/>
            <p><strong>Six Weeks of Summer is back with family day prizes to be won every week from 24th July - 2nd September</strong>
            <br /><br />
            To enter simply text the words Happy Summer to 81400 at your standard message rate, lines close <u>at midday each Friday</u> and the winner will be announced at 4pm with Ian & Michelle on Drivetime. 
            <br /><br />
            <a className="btn" target="_blank" rel='noreferrer'
              href="#">
            Scroll down for terms and conditions 
            </a> 
            <br /><br />
            <a className="btn" target="_blank" rel='noreferrer'
              href="https://www.royalcheshireshow.org/attractions/live-at-the-show/">
            Click here for terms and conditions 
            </a> 
            </p>
            <img src={competitionright} className="" alt="Happy Radio UK Competition" height={500} width={500}/>
          </div>
          <p className='pb-16 px-6 md:px-16 text-center text-xs'>Terms and conditions for Alton Towers
            <br /><br />
            Terms & conditions: Prize covers one day theme park entry for up to 4 people. Valid <u>until Tuesday 31st October 2023</u>. The Alton Towers Resort reserves the right, in its absolute discretion, to refuse entry and to close and/or alter all or any part of the facilities including closure of rides and/or attractions for technical, operational, health and safety or other reasons including overcapacity. Size, height, weight, age restrictions and medical warnings apply to certain rides and attractions. Visitors under 12 years must be accompanied by a person 18 years of age or over at the Alton Towers Resort. Prizes are non-transferable, not for resale and there is no cash alternative, photocopies will not be accepted. 
            <br /><br />
            Terms and conditions for Summer Daze at the Trafford Centre 
            <br /><br />
            Prize must be redeemed by 3rd September. Preferred entry date subject to availability. Height restrictions may apply. The winner's details will be shared with the Trafford Centre marketing team, who will arrange the prize. For more information visit  
            <a className="link" target="_blank" rel='noreferrer'
              href="https://traffordcentre.co.uk/summerdaze">
            here
            </a>
          </p>*/}
        </div>
        <div className="">
          <div className="pb-16 px-6 md:px-16 text-center text-xl leading-relaxed grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="flex justify-center">
              <img
                src={competitionleft}
                className=""
                alt="Happy Radio UK Competition"
                height={500}
                width={500}
              />
            </div>
            <div className="">
              {/* <Link to='/competition'> */}
              <h2>
                Valentine's Day Competition - Click Below for Terms and Conditions
              </h2>
              <br></br>
              <br></br>
              {/* </Link> */}
              <p>
                <a className="btn" target="_blank" rel="noreferrer" href="/Valentines TCs.pdf">
                  Click Here To View Terms & Conditions
                </a>
              </p>
            </div>
            {/* <p><strong>Six Weeks of Summer is back with family day prizes to be won every week from 24th July - 2nd September</strong>
            <br /><br />
            To enter simply text the words Happy Summer to 81400 at your standard message rate, lines close <u>at midday each Friday</u> and the winner will be announced at 4pm with Ian & Michelle on Drivetime. 
            <br /><br /> */}

            {/* <br /><br />
            <a className="btn" target="_blank" rel='noreferrer'
              href="https://www.royalcheshireshow.org/attractions/live-at-the-show/">
            Click here for terms and conditions 
            </a> 
            </p> */}
            <div className="flex justify-center">
              <img
                src={competitionright}
                className=""
                alt="Happy Radio UK Competition"
                height={500}
                width={500}
              />
            </div>
          </div>
        </div>
        <img
          src={banner}
          alt="Banner 3"
          width={1000}
          className="mx-auto py-8"
        />
        <div className="md:px-16">
          <h2>Happy Radio UK Sponsors</h2>
          <Sponsors />
        </div>
        <div className="">
          <h2>The presenters you know and love</h2>
          <Presenter limit={4} />
          <div className="text-center">
            <button
              onClick={() => navigate("/presenters")}
              className="btn mt-8"
            >
              View All Presenters
            </button>
          </div>
        </div>

        <div className="">
          <img
            className="mx-auto hidden md:block bg-cover bg-center bg-no-repeat object-cover min-h-[100px]" //
            src={banner2}
            alt="Landing Page Banner 2"
            width={1000}
            // height={600}
          />
          <img
            className="mx-auto md:hidden bg-cover bg-center bg-no-repeat object-cover min-h-[100px]" //
            src={mobileBanner2}
            alt="Landing Page Banner 2"
            width={800}
            // height={200}
          />
          {/* <p className="absolute uppercase tracking-widest bg-white text-xs md:text-2xl rounded-full px-6 py-2 font-semibold">Listen Live &#9654;</p> */}
          {/* <p className="absolute tracking-wider bg-neutral-900 text-xs md:text-sm text-white rounded-full px-6 py-2 mt-24 md:mt-48">On air now: Non Hits throwback</p>  */}
        </div>
        <div className="space-y-8">
          <h2>Latest News Headlines</h2>
          <News />
          <MeNews />
        </div>
        {/* <div className="pb-16 px-6 md:px-16 text-center leading-relaxed">
            <h2>12 Days of Christmas online Promotion on Happy Radio 2022 – Specific Rules</h2>          
            <img src={christmas} className="mx-auto pb-4" alt="Happy Radio UK Competition" height={600} width={600}/>
            <p><strong>Prizes may only be redeemed once, may not be exchanged for cash, replaced  if lost, redeemed in one sitting and is non-transferable. </strong>
              <br /> <br />
              1. Competition Promotion ('Promotion') is organised by Happy Radio.The Promotion will run from 12th December 2022 to 25th December 2022 on the Happy Radio network.
              <br /><br />
              2. IMPORTANT: The Promotion is subject to these Specific Rules and the General Terms and Conditions which are available above. Entry into the Promotion constitutes acceptance of these Specific Rules and the General Terms and Conditions.
              <br /><br />
              Details of the Promotion: 
              <br /><br />
              3. To enter the Promotion, you must text 'Happy Christmas' to 81400. Texts charged at your standard rate.
              <br /><br />
              4. Text entry will open at 07.00 on Monday 12th December 2022 and close at 18:00 daily up until Friday 23rd December 2022. Entries received before or after this time will not be included in the daily Promotion.
              <br /><br />
              5.  Within 7 days of the closing time of the Promotion, all eligible entries will be entered into a draw. One correct entry will be selected at random, and the winning entrant will be contacted by one of the Arighi Bianchi representatives via email or telephone on the details on which you entered. If such winner does not respond within 28 days from being contacted, they will forfeit their right to the prize, and we shall be entitled to select another winner by another random draw. That subsequent winner must respond to the notification email within 28 days of the date on which they are notified, or they will also forfeit the prize.
              <br /><br />
              6. We may publish and publicise your name, your nearest geographical location (for example, your nearest city, town or county) and your entry, and we may refer to your and any applicable third party's association with the Promotion and/or the prize, in any and all media, worldwide, in perpetuity, for publicity and PR purposes solely in connection with the Promotion.
              <br /><br />
              7. In relation to winners (and runners up if applicable), you understand that we and our promotion partner may: (i) interview you and your guest which we may film, photograph and/or record and; (ii) film, photograph and/or record, you and your guest in connection with the Promotion, either by telephone or in person, and we may use such films, recordings and/or photographs for publicity and PR purposes in any and all media, worldwide, as often and for as long as we consider appropriate or desirable, in connection with the Promotion. We may also publish your and your guests name, image, social media handle and/or profile picture.
              <br /><br />
              Eligibility: 
              <br /><br />
              8. The Promotion is only open to residents of the UK aged 18 and over, and who are based in the UK at the time of entry.
              <br /><br />
              9. Entry is restricted to one entry per person; duplicate entries will be excluded from the Promotion.
              <br /><br />
              The guest of winning entrant must be aged 18 or over
              Prize: 
              <br /><br />
              12. One winning entrant daily will receive the following:
              <br /><br />
              Weekend Rental of a Mercedes Benz from LSH this weekend (value: £300) C Class convertible 16th - 18th dec. 
              6 x VIP Passes to Bowdonbury Festival (May 2023) with £100 Bar Spend, Bowdonbury Festival is an annual music festival featuring great music, artisan food + drink and a host of family attractions.
              Neil Barratt Photography £500 Family Portrait Voucher - studios based in Timperley with award winning photographer; Neil Barratt.
              Teeth Whitening Package with Kiss Dental (Value £300) Dazzle and Shine With a Sparkling Smile this Christmas
              Secret Spa Package with 1 night stay at 5* Kimpton Clocktower Hotel, check into the gorgeous 5* hotel and enjoy a Secret Spa treatment from the comfort of your room. (Value £400) 
              6 x East Stand tickets to the Sale Sharks game on Saturday 14th January, plus a signed Sale Sharks Shirt (FFM) (Value £500) 
              Arighi Bianchi Cosy Home Hamper (Value £150)
              His and Hers watches from Bellagio (Value £500) 
              Lauren Stone Collection  £500 worth of Dog Treats & Accessories (Value £500) 
              Dinner for two at Balchao Hale Barns Plus Allumi Home Fragrance Hamper (Value £250)
              VIP Package for 6 to Hale Barns Carnival 2023 with £100 Bar Spend included (Value: £500) now in its 8th year; a boutique award winning family music festival with all star concerts, previous headliners have included Russell Watson, Rose Royce, Odyssey, 5 Star and many more, next years headliner promises to be their biggest yet!
              Spa Package for two at Champneys Mottram Hall with 3 course lunch (Value £250) Nestled within 270 acres of landscaped gardens, the Grade II listed Champneys Mottram Hall offers hotel and spa guests the opportunity to unwind in truly picture-perfect surroundings.
              <br /><br />
              Prize Terms and Conditions: 
              <br /><br />
              13. These terms and conditions apply to the Happy Radio competition Prize may only be redeemed once, may not be exchanged for cash, replaced if lost, redeemed in one sitting and is non-transferable. 
              <br /><br />
              14. All prizes must be claimed and booked by the prize winner within 28 days from the date of the prize providers initial correspondence with them.
              <br /><br />
              You acknowledge and agree that, without limitation to the provisions of Clause 12 of the General Terms & Conditions, our ability to fulfil a prize and/or perform any of our obligations under the Promotion Terms and Conditions could be affected by an actual or threatened epidemic, pandemic, disease or quarantine and/or any corresponding governmental action, guidance and/or ruling (including but not limited to any Foreign, Commonwealth & Development Office guidance) (collectively 'Covid Measures'). If due to any Covid Measures, we are unable to fulfil a prize or perform any of our other obligations under the Promotion Terms and Conditions, then we shall not be in breach of the Promotion Terms and Conditions.  In accordance with Clause 24 below, in these circumstances, we reserve the right to substitute the prize with another prize of equal or greater value at our absolute discretion.
              17. Any other costs and expenses which are not specifically included in the prize description are excluded and will be the sole responsibility of the winner. By way of example, food, transport to and from the hotel are not included within the prize.
              <br /><br />
              18. We reserve the right to substitute the prize with another prize of equal or greater value at our absolute discretion.
              <br /><br />
              19. For all enquiries in connection with the Promotion please contact Happy Radio at studio@happyradiouk.com
              <br /><br />
              Data Protection: 
              <br /><br />
              20. Your personal data that you provide to us will be collected and processed by us, our licensees (including Communicorp UK Limited) as well as the prize providers, and if applicable, our service providers, network operators and suppliers in order to administer and fulfil the Promotion. Your personal data may also be disclosed to the Phone-paid Services Authority, Ofcom and/or the Advertising Standards Authority at their reasonable request for regulatory purposes, for example, in order to prevent, investigate and detect crime, fraud or anti-social behaviour and comply with law enforcement agencies and applicable advertising codes.
              <br /><br />
              21. By entering a Promotion, you acknowledge that any personal data provided by you in connection with the Promotion will be processed as set out above and in accordance with our Privacy Policy which sets out full details of how we process personal data and how you can exercise your rights as a data subject. Please ensure you have read and understood our Privacy Policy before entering any Promotion. If you are required to submit a guest(s) personal data to enter a Promotion, you must ensure that your guest/companion(s) have given you consent to provide us with their personal data.
              <br /><br />
              22. You may be asked if you want to opt-in to marketing from us when you enter a Promotion. If you choose to opt-in, we will use your personal data for marketing purposes (see  Privacy Policy for more details). You can withdraw your consent to marketing at any time by unsubscribing.
              <br /><br />
              23. We and the prize providers may transfer and process personal data outside of the EEA and UK for the purposes of administering the Promotion. Where any transfers are made outside of the EEA or UK we ensure appropriate safeguards to protect your personal data are in place.
              <br /><br />
              24. We will hold your personal data for a period of up to 3 years in order to comply with our regulatory obligations.
              <br /><br />
              25. You may request the removal of your personal details from our database by studio@happyradiouk.com If you request that your personal details be removed prior to the conclusion of a Promotion, you will forfeit your right to enter the Promotion and/or claim any prize and we reserve the right to select another entrant to participate in the Promotion or withdraw the prize and select another winner, as applicable.
            </p>
          </div> */}
      </div>
    </div>
  );
}
